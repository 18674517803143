import React, { useEffect } from "react";
import Navbar from "../../components/Navbar";
import ReadAloudLayout from "./ReadAloudLayout";
import { storage as LocalStorage } from "services/config/storage";


const ReadAloud = ({history}) => {

    useEffect(() => {
      if (LocalStorage.fetch.defaultChild()?.language === "Hindi") {
        history.push("/");
      }
    });
  return (
    <div>
      <Navbar />
      <ReadAloudLayout />
    </div>
  );
};

export default ReadAloud;
