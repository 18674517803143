import React, { useEffect } from "react";
import Navbar from "../../components/Navbar";
import ReadAlongLayout from "./ReadAlongLayout";
import { storage as LocalStorage } from "services/config/storage";


const ReadAlong = ({history}) => {

  useEffect(()=>{
    if(LocalStorage.fetch.defaultChild()?.language==="Hindi"){
      history.push('/')
    }
  })

  return (
    <div>
      <Navbar />
      <ReadAlongLayout />
    </div>
  );
};

export default ReadAlong;
