/* eslint-disable jsx-a11y/anchor-is-valid */
import { storage as LocalStorage } from "services/config/storage";
import React, { Component } from "react";
import { Avatar, Dropdown, Icon, Modal } from "antd";
import Navbar from "components/Navbar";
import "./index.scss";
import { Tab, Tabs } from "react-bootstrap-tabs";
import { Accordion, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../store/user/actions";
import * as studentActions from "../../store/student/actions";
import * as attendanceActions from "../../store/attendance/actions";
import * as subscriptionsActions from "../../store/subscriptions/actions";
import * as announcementActions from "../../store/announcements/actions";
import * as homeworkActions from "../../store/Homework/actions";
import store from "../../store";
import * as testActions from "../../store/tests/actions";
import ClassDetails from "./components/ClassDetails";
import ChildrenModal from "./components/ChildrenModal";
import moment from "moment";
import AnnouncementModal from "../../components/AnnouncementModal";
import { NextClass } from "./components/Cards/NextClass";
import { getTimeComponents } from "./utils";
import TeacherStudentCard from "./components/Cards/TeacherStudentCard";
import UserCard from "./components/Cards/UserCard";
import AnnouncementsCard from "./components/Cards/AnnouncementsCard";
import { generateFilterUrl } from "../../utils/commonUtils";
import isEmpty from "lodash/isEmpty";
import CalendarNext from "../../assets/image/calendar-next.svg";
import CalendarPrev from "../../assets/image/calendar-previous.svg";
import { Spin } from "antd";
import ReportsCard from "./components/Cards/ReportsCard";
import BatchSelector from "./components/BatchSelector";
import RemainingClassCard from "./components/Cards/RemainingClassCard";
import CustomerSupportModal from "../../components/CustomerSupportModal";
import TestsModal from "../../components/TestsModal";
import * as EVENT from "../../utils/eventKeys";
import { Analytics } from "../../services/analytics";
import questionIcon from "../../assets/image/question-icon.png";
import { getAndSetChild } from "../../store/user/actions";
import ErrorMessageModal from "./components/ErrorMessageModal";
import plusIconImg from "../../assets/image/plus-icon.png";
import * as teacherActions from "../../store/teacher/actions";
import {
  MainBodyContainer,
  MainBodyContentContainer,
  PlusButton,
  PlusIcon,
} from "./custom-styled";
import AddAssignment from "../../components/AddAssignment";
import AssignmentDetails from "./components/Cards/AssignmentCard/AssignmentDetails";
import ShowInfoModal from "./showInfoModal";
import GuidedPathUnitsCard from "./components/Cards/GuidedPathUnitsCard";
import GuidedPathUnitsModal from "./components/GuidedPathUnitsModal";
import ToastComponent from "../../components/ToastComponent/ToastComponent";
const CLASS_JOIN_BUFFER_SECONDS = 1800;

class ActiveUserPage extends Component {
  state = {};

  constructor(props, context) {
    super(props, context);

    this.state = {
      Id_batch: "",
      activeKey: "1",
      showDetailsModal: false,
      freadom_data: LocalStorage.fetch.freadomChild(),
      parent_data: LocalStorage.fetch.parentData(),
      auth: LocalStorage.fetch.authToken(),
      typeOfClass: "upcoming",
      previousClasses: [],
      upcomingClasses: [],
      filters: {
        upcoming: {
          offset: 0,
        },
        previous: {
          offset: 0,
        },
      },
      errorMessageModalVisible: false,
      LookerUrl: "",
      GPLookerUrl: "",
      assignmentCreateForm: false,
      teacherAssignmets: null,
      updatedChild: null,
      showInfoMessage: false,
      showGPUnitsPopUp: false,
      GPUnits: [],
      showToast: { show: false, message: "", type: "" },
    };

    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(activeKey) {
    this.setState({ activeKey });
  }

  componentDidMount = async () => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");

    if (!this.state.auth && !token) {
      console.log("not auth");
      this.props.history.push("/login");
      // this.props.history.push("/onboarding", { urlToRedirect: this.props.location.pathname });
    } else {


      this.getClasses("", this.props?.reduxData?.user?.selectedBatchId);

      window.addEventListener("popstate", (e) => {
        //this.props.history.push("/");
      });

      // check for auth token

      if (this.state.auth && !token) {
        let activeChild = await LocalStorage.fetch.defaultChild();
        // // console.log("activeChild", activeChild);
        if (activeChild) {
          let updated_child = await getAndSetChild(
            store.dispatch,
            {},
            activeChild.id,
            true
          );
          let currentActiveChild = await LocalStorage.fetch.defaultChild();
        }

        this.fetchAssignmentsOfTeacher();
        this.fetchAllGPUnits();
      } else if (token && !this.state.auth) {
        const params = new URLSearchParams(window.location.search);
        // get the access token
        const token = params.get("token");
        // set the token in the local storage
        console.log("token", token);
        const res = await this.props.loginWithAuthToken(token).then((res) => {
          // clear all async api calls
          setTimeout(() => {
            window.location.reload();
          }, 1300);
        });
        console.log("res", res);
      }
    }
  };

  fetchAssignmentsOfTeacher = async () => {
    console.log("fetchAssignmentsOfTeacher");
    if (this.state.auth) {
      const assignments = await this.props.fetchAllAssignments();
      if (assignments) {
        // assignments.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
        this.setState({ teacherAssignmets: assignments });
      }
    }
  };
  sendAnalytics = (event, data = {}) => {
    let payload = {};
    if (Object.keys(data).length > 0) {
      payload[EVENT.SESSION_TIME] = data.time;
      payload[EVENT.SESSION_DATE] = data.dat;
      payload[EVENT.SESSION_TITLE] = data.name;
      payload[EVENT.BUTTON_LOCATION] = data.button ? data.button : null;
    }
    payload[EVENT.SESSION_ID] = LocalStorage.fetch.sessionData()?.session_data;
    payload[EVENT.TIMESTAMP] = new Date();
    payload[EVENT.PLATFORM] = "Web";
    payload[EVENT.PHONE_NUMBER] = LocalStorage.fetch.defaultChild()?.contact_no;
    payload[EVENT.EMAIL] = LocalStorage.fetch.defaultChild()?.email;
    payload[EVENT.CHILD_ID] = LocalStorage.fetch.defaultChild()?.id;
    payload[EVENT.CHILD_LEVEL] = LocalStorage.fetch.defaultChild()?.grade_level;
    //FREAD-1342
    // Analytics.logEvents(event, payload);
    //FREAD-1342
  };
  //FREAD-1342
  sendCtEvents = (event) => {
    let payload = {};
    payload[EVENT.CHILD_ID] = LocalStorage.fetch.defaultChild()?.id;
    payload[EVENT.CHILD_LEVEL] = LocalStorage.fetch.defaultChild()?.grade_level;
    Analytics.logEvents(event, payload);
  };
  //FREAD-1342
  getClasses = async (typeOfClass, batchToFetchFor) => {
    let filters = { ...this.state.filters };
    let resPrevious = {},
      resUpcoming = {},
      urlParams = "";
    this.setState({
      isLoading: true,
    });

    let batchId =
      batchToFetchFor ||
      this.props.reduxData.student?.batchDetails?.[0]?.batch_id;
    switch (typeOfClass) {
      case "previous":
        if (this.props.reduxData.student?.classes?.previous?.has_next) {
          urlParams = generateFilterUrl(filters.previous);
          [resPrevious] = await this.props.getStudentClasses(
            batchId,
            urlParams,
            "previous"
          );
          this.isFetchingMoreData = false;
        }
        break;
      case "upcoming":
        if (this.props.reduxData.student?.classes?.upcoming?.has_next) {
          urlParams = generateFilterUrl(filters.upcoming);
          [{}, resUpcoming] = await this.props.getStudentClasses(
            batchId,
            urlParams,
            "upcoming"
          );
          this.isFetchingMoreData = false;
        }
        break;
      default:
        urlParams = generateFilterUrl({
          offset: 0,
        });
        [resPrevious, resUpcoming] = await this.props.getStudentClasses(
          batchId,
          urlParams,
          null
        );
        this.isFetchingMoreData = false;
        break;
    }
    this.setState({
      isLoading: false,
      resPrevious,
      resUpcoming,
      previousClasses: !isEmpty(resPrevious)
        ? [
            ...resPrevious?.class_sessions.reverse(),
            ...this.state.previousClasses,
          ]
        : [...this.state.previousClasses],
      upcomingClasses: !isEmpty(resUpcoming)
        ? [
            ...resUpcoming?.class_sessions.reverse(),
            ...this.state.upcomingClasses,
          ]
        : [...this.state.upcomingClasses],
    });
  };

  handleStickyClass(operation) {
    let tabs = document.getElementsByClassName("nav nav-tabs");
    if (tabs && tabs?.length > 0) {
      if (operation === "add") tabs[0].classList.add("sticky");
      else tabs[0].classList.remove("sticky");
    }
  }

  shouldJoinClass = (nextClassStartTime, sessionClassName) => {
    const currentDate = moment().utc(true);
    let nextClassTime = moment(nextClassStartTime).utc(true);

    let currentTime = currentDate;

    if (Math.abs(nextClassTime.diff(currentTime, "seconds")) <= 1800) {
      return true;
    } else return false;
  };

  renderAttendanceStatus(status) {
    if (!status) {
      return "NONE";
    }
    switch (status) {
      case "UNMARKED":
        return null;
      case "REPEAT":
      case "REPEATED":
        return <button className="repeat-btn">{status}</button>;
      case "ATTENDED":
      case "PRESENT":
        return <button className="attended-btn">{status}</button>;
      case "MISSED":
      case "ABSENT":
        return <button className="missed-btn">{status}</button>;
      default:
        return null;
    }
  }
  joinClass = (c) => {
    // // // console.log("c>>>>", c);
    this.sendAnalytics(EVENT.VIEW_UPCOMING_CLASS, {
      time: getTimeComponents(
        "timeSlot",
        c.event.start_timestamp,
        c.event.end_timestamp
      ),
      date:
        getTimeComponents(
          "date",
          c.event.start_timestamp,
          c.event.end_timestamp
        ) +
        " " +
        getTimeComponents(
          "month",
          c.event.start_timestamp,
          c.event.end_timestamp
        ),
      name: c.session?.name,
      button: "upcoming class view",
    });
    window.open(c.class_link, "_blank");
  };

  showPtmTag = () => Math.random() * 100 > 50; //Add here the logic to check for PTM key in API response

  renderClassList = (classes, isUpcomingClass) => {
    // // // console.log("classes<<<<", classes);
    return classes?.map((c, index) => {
      // // // console.log("c<<<<", c);
      return (
        <div className="tabContent">
          <div
            className={`tab-pane tabPane ${isUpcomingClass ? "upcoming" : ""}`}
          >
            <input
              type="radio"
              id={`control_${index + 1}`}
              name="select"
              value={index + 1}
              checked
            />
            <label htmlFor={`control_${index + 1}`}>
              <div className="bg-white card mb-4 labelTo">
                <div
                  className={
                    c.session.label == null || c.session.label === false
                      ? "hide"
                      : "ptmLabelClass"
                  }
                >
                  PTM
                </div>
                <div
                  //FREAD-1342
                  id={["PREVIOUS_SESSION_", index + 1].join("")}
                  //FREAD-1342
                  className="gold-members p-4"
                  onClick={() => {
                    if (!isUpcomingClass) {
                      this.handleStickyClass("remove");
                      //FREAD-1342
                      this.sendCtEvents(EVENT.FLIVE_PREVIOUS_CLASSES_CARD);
                      //FREAD-1342
                      this.sendAnalytics(EVENT.VIEW_PREVIOUS_CLASS, {
                        time: getTimeComponents(
                          "timeSlot",
                          c.event.start_timestamp,
                          c.event.end_timestamp
                        ),
                        date:
                          getTimeComponents(
                            "date",
                            c.event.start_timestamp,
                            c.event.end_timestamp
                          ) +
                          " " +
                          getTimeComponents(
                            "month",
                            c.event.start_timestamp,
                            c.event.end_timestamp
                          ),
                        name: c.session?.name,
                      });
                      this.setState({
                        showDetailsModal: true,
                        selectedClassData: {
                          ...c,
                          attendanceStatus: c.attendance.attendance_status,
                          attendanceComments: c.attendance.attendance_comments,
                          classTime: { ...c.events },
                          cid: c?.id,
                          studentID: this.props.reduxData?.student?.id,
                          classInfo: c,
                        },
                      });
                    }
                  }}
                >
                  <div className="media">
                    <div className="updatedate text-center ">
                      <span className="float-right d-block dateTitle">
                        {getTimeComponents(
                          "date",
                          c.event.start_timestamp,
                          c.event.end_timestamp
                        ) +
                          " " +
                          getTimeComponents(
                            "month",
                            c.event.start_timestamp,
                            c.event.end_timestamp
                          )}
                      </span>
                      <p className=" mb-1 d-block timeTitle">
                        {getTimeComponents(
                          "timeSlot",
                          c.event.start_timestamp,
                          c.event.end_timestamp
                        )}
                      </p>
                    </div>

                    <div className="media-body">
                      <div
                        className={
                          isUpcomingClass
                            ? "upcoming flex-wrap"
                            : "previous flex-wrap"
                        }
                      >
                        <div className="name-desc-width ">
                          <h6 className="mb-1">{c.session?.name}</h6>
                          <p className="mb-0">{c.session?.description}</p>
                        </div>
                        {!isUpcomingClass && (
                          <div>
                            {this.renderAttendanceStatus(
                              c.attendance.attendance_status
                            )}
                          </div>
                        )}
                        {isUpcomingClass &&
                        this.shouldJoinClass(c.event.start_timestamp) ? (
                          <div style={{ padding: "0 1px" }}>
                            <Button
                              title="Join Class"
                              className={"class-join-btn"}
                              //FREAD-1342
                              id="FLV_JOIN_UP_BTN"
                              //FREAD-1342
                              onClick={() => {
                                //FREAD-1342
                                this.sendCtEvents(
                                  EVENT.FLIVE_UPCOMING_CLASSES_JOIN_BUTTON
                                );
                                //FREAD-1342
                                this.joinClass(c);
                              }}
                            >
                              JOIN
                            </Button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </label>
          </div>
        </div>
      );
    });
  };

  onScrollClasses = (event) => {
    // // console.log("event.target===",event.target.scrollHeight,event.target.scrollTop,event.target.clientHeight)
    if (
      window.innerHeight < window.innerWidth &&
      (Math.ceil(event.target.scrollHeight - event.target.scrollTop) ===
        event.target.clientHeight ||
        Math.floor(event.target.scrollHeight - event.target.scrollTop) ===
          event.target.clientHeight) &&
      !this.isFetchingMoreData
    ) {
      this.handlePageChange();
    }
  };

  handlePageChange = () => {
    this.isFetchingMoreData = true;
    let { typeOfClass } = this.state;
    let nextPage = "";
    if (typeOfClass === "previous")
      nextPage = this.props.reduxData.student?.classes?.previous?.next_page;
    else nextPage = this.props.reduxData.student?.classes?.upcoming?.next_page;
    this.setState(
      {
        filters: {
          ...this.state.filters,
          [typeOfClass]: {
            offset: nextPage,
          },
        },
      },
      () => {
        this.getClasses(typeOfClass);
      }
    );
  };
  setClassType = async (typeOfClass) => {
    if (typeOfClass === 0) {
      //FREAD-1342
      //this.sendAnalytics(EVENT.VIEW_UPCOMING_CLASS_LIST);
      this.sendCtEvents(EVENT.FLIVE_UPCOMING_CLASSES_HEADER_TAB);
      //FREAD-1342
      typeOfClass = "upcoming";
    } else {
      //FREAD-1342
      //this.sendAnalytics(EVENT.VIEW_PREVIOUS_CLASS_LIST);
      this.sendCtEvents(EVENT.FLIVE_PREVIOUS_CLASSES_HEADER_TAB);
      //FREAD-1342
      typeOfClass = "previous";
    }

    if (typeOfClass)
      this.setState({
        typeOfClass,
        filters: {
          upcoming: {
            offset: 0,
          },
          previous: {
            offset: 0,
          },
        },
      });
  };
  checkSectionSelected = (section) => {
    const defaultChild = LocalStorage.fetch.defaultChild();
    console.log(defaultChild);

    if (!defaultChild?.school_class || defaultChild.school_class.length === 0) {
      console.log(
        "Section not selected, showing error message:",
        this.state.errorMessageModalVisible
      );
      if (!this.state.errorMessageModalVisible) {
        this.setState({ errorMessageModalVisible: true });
      }
    } else {
      this.setState({
        errorMessageModalVisible: false,
        showChildrenListPopup: true,
      });
    }
  };

  childUpdate = (sectionName) => {
    //get grade, section of teacher

    const defaultChild = LocalStorage.fetch.defaultChild();
    // // console.log("defaultChild", defaultChild);
    const grade = defaultChild?.grade?.name;
    const section_id = defaultChild?.TeacherSchoolClass;
    if (grade.startsWith("G")) {
      var words = grade.split(" ");
      var firstWord = words[0];
      var secondWord = words[1];
    } else if (grade.startsWith("S")) {
      var firstWord = "Senior";
      var secondWord = "KG";
    } else if (grade.startsWith("J")) {
      var firstWord = "Junior";
      var secondWord = "KG";
    } else if (grade.startsWith("N")) {
      var firstWord = "Nursery";
    }

    const schoolCode = defaultChild?.school_code;

    let looker_link = "";
    let GP_looker_link = "";
    if (grade.startsWith("G")) {

        looker_link = `https://lookerstudio.google.com/embed/reporting/2d58352b-3be3-4198-9e53-3fa31927ea77/page/VD8VE?params=%7B%22df6%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${schoolCode}%22,%22df3%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${sectionName}%22,%22df4%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${firstWord}%2520${secondWord}%22%7D`

      GP_looker_link =
        "https://lookerstudio.google.com/embed/reporting/28eb79b0-54ae-4de0-a09c-5b542f25dc71/page/p_g9hmfp4mfd/edit?params=%7B%22df40%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580" +
        schoolCode +
        "%22,%22df38%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580" +
        firstWord +
        "%2520" +
        secondWord +
        "%22,%22df39%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580" +
        sectionName +
        "%22%7D";
    } else if (grade.startsWith("N")) {

         looker_link = `https://lookerstudio.google.com/embed/reporting/2d58352b-3be3-4198-9e53-3fa31927ea77/page/VD8VE?params=%7B%22df6%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${schoolCode}%22,%22df3%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${sectionName}%22,%22df4%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580%2520%2520%2520${firstWord}%22%7D`;

      GP_looker_link =
        "https://lookerstudio.google.com/embed/reporting/28eb79b0-54ae-4de0-a09c-5b542f25dc71/page/p_g9hmfp4mfd/edit?params=%7B%22df40%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580" +
        schoolCode +
        "%22,%22df38%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580" +
        firstWord +
        "%22,%22df39%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580" +
        sectionName +
        "%22%7D";
    } else if (grade.startsWith("J")) {


         looker_link = `https://lookerstudio.google.com/embed/reporting/2d58352b-3be3-4198-9e53-3fa31927ea77/page/VD8VE?params=%7B%22df6%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${schoolCode}%22,%22df3%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${sectionName}%22,%22df4%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580%2520%2520${firstWord}%2520${secondWord}%22%7D`;

      GP_looker_link =
        "https://lookerstudio.google.com/embed/reporting/28eb79b0-54ae-4de0-a09c-5b542f25dc71/page/p_g9hmfp4mfd/edit?params=%7B%22df40%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580" +
        schoolCode +
        "%22,%22df38%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580" +
        firstWord +
        "%2520" +
        secondWord +
        "%22,%22df39%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580" +
        sectionName +
        "%22%7D";
    } else if (grade.startsWith("S")) {


       looker_link = `https://lookerstudio.google.com/embed/reporting/2d58352b-3be3-4198-9e53-3fa31927ea77/page/VD8VE?params=%7B%22df6%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${schoolCode}%22,%22df3%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${sectionName}%22,%22df4%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580%2520${firstWord}%2520${secondWord}%22%7D`;

      GP_looker_link =
        "https://lookerstudio.google.com/embed/reporting/28eb79b0-54ae-4de0-a09c-5b542f25dc71/page/p_g9hmfp4mfd/edit?params=%7B%22df40%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580" +
        schoolCode +
        "%22,%22df38%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580" +
        firstWord +
        "%2520" +
        secondWord +
        "%22,%22df39%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580" +
        sectionName +
        "%22%7D";
    }

    console.log("looker link:", looker_link);
    console.log("gp link:", GP_looker_link);
    this.setState({
      LookerUrl: looker_link,
      GPLookerUrl: GP_looker_link,
      updatedChild: section_id,
    });

    this.fetchAllGPUnits();
  };

  handleAddClickButton = () => {
    // this.setState({ assignmentCreateForm: true });
    this.setState({ showInfoMessage: true });
  };

  deactivateAssignment = (data, e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("data", data);

    const payload = {
      is_deleted: !data.is_deleted,
      id: data.id,
    };
    this.props.deactivateAssignment(payload).then((res) => {
      console.log("res", res);
    });
    this.setState({ teacherAssignmets: null });

    // wait for 1 second
    setTimeout(() => {
      this.fetchAssignmentsOfTeacher();
    }, 1000);
  };
  openChildrenModal = () => {
    // this.sendAnalytics(EVENT.VIEW_BATCHMATES);
    this.handleStickyClass("remove");
    // this.setState({ showChildrenListPopup: true });
    this.checkSectionSelected();
  };

  openGPUnitsModal = () => {
    this.handleStickyClass("remove");
    this.setState({ showGPUnitsPopUp: true });
    // this.checkSectionSelected();
  };

  fetchAllGPUnits = async () => {
    let currentChild = LocalStorage.fetch.defaultChild();
    const section_id = currentChild?.TeacherSchoolClass
      ? currentChild?.TeacherSchoolClass
      : this.state.updatedChild;
    const units = await this.props.getGPUnits({
      grade: currentChild?.grade?.id,
      // section: this.state.updatedChild,
      section: section_id,
    });
    if (units) {
      this.setState({
        GPUnits: units,
      });
    }
  };
  render() {
    // // console.log(">>>>>>props", this.props);

    //Bringing classes from all batches in a single array
    /** assuming that single child is on single batch only. If child-batch relation is 1*n then uncomment and handle accordingly */
    // let expandedClasses = [];
    // if (this.props.reduxData.student.classes) {
    //     this.props.reduxData.student.classes.forEach(element => {
    //         expandedClasses = [...expandedClasses, ...element];
    //     })
    // }

    //Current date : used to determine upcoming and previous classes in later code

    //Next class info to be displayed
    const nextClassToAttend = this.state.upcomingClasses?.[0];
    let nextClassTime = {
      classStartDateTime: nextClassToAttend?.event?.start_timestamp,
      classEndDateTime: nextClassToAttend?.event?.end_timestamp,
    };
    nextClassTime.join = this.shouldJoinClass(
      nextClassToAttend?.event?.start_timestamp
    );
    let upcomingClasses = this.state.upcomingClasses || [];
    let previousClasses = this.state.previousClasses || [];

    let upcomingClassesTabs = this.renderClassList(upcomingClasses, true) || [];
    let previousClassesTabs =
      this.renderClassList(previousClasses, false) || [];

    return (
      <div className="component-flive main-wrapper">
        {/* //FLV-113 */}
        {!this.props.reduxData?.user?.navbarFlag && <Navbar />}
        {/* //FLV-113 */}

        <div
          className={[
            "wrapperSecion",
            this.props.reduxData?.appConfig?.showNavbar ? "" : "padding-top-0",
            //FLV-113
            this.props.reduxData?.user?.navbarFlag ? "padding-top-0" : "",
            //FLV-113
          ].join(" ")}
        >
          <ClassDetails
            sendCtEvents={this.sendCtEvents}
            selectedClassData={this.state.selectedClassData}
            visible={this.state.showDetailsModal}
            onClose={() => {
              this.setState(
                {
                  showDetailsModal: !this.state.showDetailsModal,
                },
                () => setTimeout(() => this.handleStickyClass("add"), 500)
              );
            }}
          />
          <div className="container overflow-hidden newcontainer">
            <div
              className="row align-items-start column-reverse"
              style={{ width: "100%" }}
            >
              <div
                className="col-md-3 left-column updatedleftcolumn"
                style={{ height: "90vh" }}
              >
                <div>
                  <UserCard
                    sendAnalytics={(event) => {
                      this.sendAnalytics(event);
                    }}
                    //FREAD-1342
                    sendCtEvents={this.sendCtEvents}
                    childUpdate={this.childUpdate}
                  />
                  {/* <RemainingClassCard
                    //FREAD-1342
                    sendCtEvents={this.sendCtEvents}
                    //FREAD-1342
                  /> */}
                </div>
                <TeacherStudentCard
                  openChildrenModal={this.openChildrenModal}
                  //FREAD-1342
                  sendCtEvents={this.sendCtEvents}
                  //FREAD-1342
                />
                {this.state.GPUnits &&
                  LocalStorage.fetch.defaultChild()?.school_details
                    ?.guided_path_type && (
                    <GuidedPathUnitsCard
                      openGPUnitsModal={this.openGPUnitsModal}
                      sendCtEvents={this.sendCtEvents}
                      section={this.state.updatedChild}
                    />
                  )}

                {/* <ReportsCard
                  //FREAD-1342
                  sendCtEvents={this.sendCtEvents}
                  //FREAD-1342
                  onShow={() => this.props.history.push("/user/reports")}
                /> */}
              </div>
              <div className="col-md-9 center-column">
                {/* <BatchSelector
                  onSelect={(batchId) => {
                    this.setState(
                      {
                        isLoading: true,
                        resPrevious: {},
                        resUpcoming: {},
                        previousClasses: [],
                        upcomingClasses: [],
                        Id_batch:batchId
                      },
                      () => this.getClasses("", batchId),
                      this.props.getHomework(batchId)
                    );
                  }}
                /> */}
                <div className="osahan-account-page-right tab-container-list ">
                  <Tabs
                    activeKey={this.state.typeOfClass}
                    onSelect={this.setClassType}
                    defaultActiveKey={this.state.typeOfClass}
                    className="tab-box "
                  >
                    <Tab
                      eventKey="upcoming"
                      tabClassName={"upcoming-tab"}
                      label={
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            height: "100%",
                          }}
                          //FREAD-1342
                          id="FLV_UPCOMING_HEADER_TAB"
                          //FREAD-1342
                        >
                          <img className="tabIcon " src={CalendarNext} />
                          OVERALL SUMMARY
                        </div>
                      }
                    >
                      <div className="tab-1">
                        <Accordion>
                          <div
                            style={{
                              width: "100%",
                              height:
                                window.innerWidth > 1000 ? "80vh" : "70vh",
                            }}
                          >
                            {this.state.LookerUrl ? (
                              <iframe
                                title="Embedded Content"
                                src={this.state.LookerUrl}
                                width="100%"
                                height="100%"
                                style={{ border: "none" }}
                                allowFullScreen
                                sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
                              ></iframe>
                            ) : (
                              <div className="loading">
                                <span>No Data</span>
                              </div>
                            )}
                          </div>
                        </Accordion>

                        {/* Section: Upcoming Classes*/}
                        {/* <div
                            // id={'upcoming-classes'}
                            onScroll={this.onScrollClasses}
                            className={[
                              "classes-list",
                              this.state.expandedListView === "upcoming"
                                ? "-expanded"
                                : "",
                            ].join("")}
                          >
                            {upcomingClassesTabs.length ? (
                              ""
                            ) : (
                              <h6
                                style={{
                                  marginTop: "40px",
                                  textAlign: "center",
                                }}
                              >
                                No Upcoming Classes
                              </h6>
                            )}
                            {upcomingClassesTabs}
                            {this.state.isLoading && (
                              <div className={"load-more"}>
                                <Spin />
                                Loading...
                              </div>
                            )}
                          </div> */}

                        {/* </Accordion> */}
                        {/* {upcomingClassesTabs?.length > 3 && (
                          <button
                            onClick={() => {
                              if (
                                !this.state.expandedListView
                                && this.props.reduxData.student?.classes?.upcoming?.has_next
                              ) {
                                return this.handlePageChange();
                              }
                              this.setState({
                                expandedListView: this.state.expandedListView
                                  ? null
                                  : "upcoming",
                              });
                            }}
                            className="gray-btn"
                          >
                            {this.state.expandedListView === "upcoming"
                              ? // this.props.reduxData.student?.classes?.upcoming
                                //     ?.has_next
                                //   ? "Show More"
                                //   : "See Less"
                                "See Less"
                              : "See All"}
                            {this.state.isLoading && <Spin />}
                          </button>
                        )} */}
                      </div>
                    </Tab>
                    <Tab
                      eventKey="previous"
                      tabClassName={"previous-tab"}
                      label={
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            height: "100%",
                          }}
                          //FREAD-1342
                          id="FLV_PREVIOUS_HEADER_TAB"
                          //FREAD-1342
                        >
                          <img className="tabIcon " src={CalendarNext} />
                          GUIDED PATH PROGRESS
                        </div>
                      }
                    >
                      <div className="tab-2 tab-pane">
                        <Accordion>
                          {this.state.GPLookerUrl ? (
                            <iframe
                              title="Embedded Content"
                              src={this.state.GPLookerUrl}
                              width="100%"
                              height="800"
                              allowFullScreen
                            ></iframe>
                          ) : (
                            <div className="loading">
                              <span>No Data</span>
                            </div>
                          )}

                          {/* Section: Previous Classes*/}
                          {/* <div
                            // id={'previous-classes'}
                            onScroll={this.onScrollClasses}
                            className={[
                              "classes-list",
                              this.state.expandedListView === "previous"
                                ? "-expanded"
                                : "",
                            ].join("")}
                          >
                            {previousClassesTabs.length ? (
                              ""
                            ) : (
                              <h6
                                style={{
                                  marginTop: "40px",
                                  textAlign: "center",
                                }}
                              >
                                No Previous Classes
                              </h6>
                            )}
                            {previousClassesTabs}
                            {this.state.isLoading && (
                              <div className={"load-more"}>
                                <Spin />
                                Loading...
                              </div>
                            )}
                          </div> */}
                        </Accordion>
                      </div>
                    </Tab>
                    <Tab
                      eventKey="previous"
                      tabClassName={"previous-tab"}
                      label={
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            height: "100%",
                          }}
                          //FREAD-1342
                          id="FLV_PREVIOUS_HEADER_TAB"
                          //FREAD-1342
                        >
                          <img className="tabIcon " src={CalendarNext} />
                          Assignments
                        </div>
                      }
                    >
                      <div className="tab-2 tab-pane">
                        {previousClassesTabs?.length > 3 && (
                          <button
                            onClick={() => {
                              if (
                                !this.state.expandedListView &&
                                this.props.reduxData.student?.classes?.previous
                                  ?.has_next
                              ) {
                                this.handlePageChange();
                              }
                              this.setState({
                                expandedListView: this.state.expandedListView
                                  ? null
                                  : "previous",
                              });
                            }}
                            className="gray-btn"
                          >
                            {this.state.expandedListView === "previous"
                              ? // this.props.reduxData.student?.classes?.previous
                                //     ?.has_next
                                //   ? "Show More"
                                //   :
                                "See Less"
                              : "See All"}
                            {this.state.isLoading && <Spin />}
                          </button>
                        )}
                        <div className="assignments_container">
                          <AssignmentDetails
                            fetchAllAssignments={() => {
                              this.fetchAssignmentsOfTeacher();
                            }}
                            allAssignments={this.state.teacherAssignmets}
                            deactivateAssignment={this.deactivateAssignment}
                          />
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
                {/* <AnnouncementsCard

                  isMobileView={false}
                  openCustomerSupportModal={() => {
                    this.handleStickyClass("remove");
                    this.setState({ showCustomerSupportModal: true });
                  }}
                  openAnnouncementsModal={() => {
                    this.sendAnalytics(EVENT.VIEW_UPDATES);
                    this.handleStickyClass("remove");
                    this.setState({ announcementsModalVisible: true });
                    //FREAD-1342
                    this.sendCtEvents(EVENT.FLIVE_HOMEWORK_VIEW_ALL);
                    //FREAD-1342
                  }}
                  openTestsModal={() => {
                    this.handleStickyClass("remove");
                    this.setState({ testModalVisible: true });
                  }}

                /> */}
              </div>

            </div>
          </div>
        </div>
        <CustomerSupportModal
          visible={this.state.showCustomerSupportModal}
          closeModal={() => {
            this.setState(
              {
                showCustomerSupportModal: !this.state.showCustomerSupportModal,
              },
              () => setTimeout(() => this.handleStickyClass("add"), 500)
            );
          }}
        />
        <ChildrenModal
          batchId={this.props.reduxData?.student?.batchDetails?.[0]?.batch_id}
          visible={this.state.showChildrenListPopup}
          updatedChild={this.state.updatedChild}
          closeModal={() => {
            this.setState(
              {
                showChildrenListPopup: !this.state.showChildrenListPopup,
              },
              () => setTimeout(() => this.handleStickyClass("add"), 500)
            );
          }}
        />
        <GuidedPathUnitsModal
          visible={this.state.showGPUnitsPopUp}
          section={this.state.updatedChild}
          units={this.state.GPUnits && this.state.GPUnits}
          fetchAllUnits={this.fetchAllGPUnits}
          updateAssignments={() => {
            this.fetchAssignmentsOfTeacher();
          }}
          closeModal={() => {
            this.setState(
              {
                showGPUnitsPopUp: !this.state.showGPUnitsPopUp,
              },
              () => setTimeout(() => this.handleStickyClass("add"), 500)
            );
          }}
          showToast={this.state.showToast}
          setShowToast={(message) => {
            this.setState({
              showToast: { ...this.state.showToast, show: true, message },
            });
            setTimeout(() => {
              this.setState({
                showToast: {
                  ...this.state.showToast,
                  show: false,
                  message: "",
                },
              });
            }, 2000);
          }}
        />
        <ErrorMessageModal
          visible={this.state.errorMessageModalVisible}
          data={"Please select a section"}
          closeModal={() => {
            this.setState(
              {
                errorMessageModalVisible: !this.state.errorMessageModalVisible,
              },
              () => setTimeout(() => this.handleStickyClass("add"), 500)
            );
          }}
        />

        <AnnouncementModal
          //FREAD-1342
          sendCtEvents={this.sendCtEvents}
          //FREAD-1342
          reduxData={this.props.reduxData}
          visible={this.state.announcementsModalVisible}
          closeModal={() => {
            this.setState(
              {
                announcementsModalVisible:
                  !this.state.announcementsModalVisible,
              },
              () => setTimeout(() => this.handleStickyClass("add"), 500)
            );
          }}
        />
        <TestsModal
          visible={this.state.testModalVisible}
          closeModal={() => {
            this.setState(
              {
                testModalVisible: !this.state.testModalVisible,
              },
              () => setTimeout(() => this.handleStickyClass("add"), 500)
            );
          }}
        />
        {/* <Modal
          title="Create Assignment"
          centered
          visible={this.state.assignmentCreateForm}
          closeModal={() => {
            this.setState({
              assignmentCreateForm: !this.state.assignmentCreateForm,
            });
          }}
          footer={null}
          key={3}
          destroyOnClose
        >
          <AddAssignment
            close={() => {
              this.setState({
                assignmentCreateForm: false,
              });
            }}
            updateAssignments={() => {
              this.fetchAssignmentsOfTeacher();
            }}
          />
        </Modal> */}

        <Modal
          centered
          // width="70%"
          // height="50%"
          visible={this.state.showInfoMessage}
          onCancel={() => this.setState({ showInfoMessage: false })}
          footer={null}
          key={1}
          destroyOnClose
          closable={true}
        >
          <ShowInfoModal
            closeModal={() => this.setState({ showInfoMessage: false })}
            onok={() =>
              this.setState({
                assignmentCreateForm: true,
                showInfoMessage: false,
              })
            }
            onClickHere={() => {
              // this.setState({ showInfoMessage: false });
              this.openChildrenModal();
            }}
          />
        </Modal>

        {this.state.showToast.show && (
          <ToastComponent message={this.state.showToast.message} />
        )}
      </div>
    );
  }
}

export default connect(
  (state) => ({ reduxData: { ...state } }),
  (dispatch) =>
    bindActionCreators(
      {
        ...userActions,
        ...studentActions,
        ...attendanceActions,
        ...testActions,
        ...subscriptionsActions,
        ...announcementActions,
        ...homeworkActions,
        ...teacherActions,
      },
      dispatch
    )
)(ActiveUserPage);
