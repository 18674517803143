import { initialState } from "./state";

export const guidedPath = (state = initialState, action) => {
  console.log(action)
  switch (action.type) {
    case "SET_UNIT_LIST": {
      return Object.assign({}, state, {
        unitList: action.data.unitList,
        unitMessage: action.data.unitMessage,
        isUnitsLoaded: true,
        gpType:action.data.gpType
      });
    }
    case "SET_TASK_LIST": {
      return {
        ...state,
        taskList: action.data,
      };
    }
    case "UPDATE_TASK_LIST": {
      return {
        ...state,
        taskList: {tasks:state.taskList?.tasks?.map((t) => {
          if (t.id === action.data.task_id) {
            return { ...t, task_status: "completed" };
          }
          return t;
        })}
      };
    }
    case "UPDATE_UNIT_LIST": {
      return {
        ...state,
        unitList: state.unitList.map((u) => {
          if (u.id === action.data) {
            return { ...u, is_completed: true };
          }
          return u;
        }),
      };
    }
    case "SET_UNIT_LOADING": {
      return Object.assign({}, state, {
        isUnitsLoaded: false,
        unitList:[]
      });
    }
    case "SET_SECTIONS": {
      let sections = action.data?.map((section)=>{
        return({
          id: section.id,
          name:section.name
        })
      })
      console.log(sections,action)
      return{
        ...state,
        sections: sections
      }
    }
    default:
      return state;
  }
};
