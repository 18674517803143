import { initialState } from "./state";

export const collections = (state = initialState, action) => {
  switch (action.type) {
    case "SET_COLLECTIONS_LIST": {
      return Object.assign({}, state, {
        collectionsList: action.data,
      });
    }
    case "SET_ACTIVE_COLLECTIONS": {
      return Object.assign({}, state, {
        activeCollections: action.data,
      });
    }
    case "SET_COLLECTION_RES": {
      return Object.assign({}, state, {
        collectionsRes: action.data,
        loadingCollections: false,
      });
    }
    case "SET_COLLECTION_LOADING": {
      return Object.assign({}, state, {
        loadingCollections: action.data,
      });
    }
    default:
      return state;
  }
};

