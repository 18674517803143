import { v4 as uuidv4 } from "uuid";

const LS_KEY = {
  flive_token: "fliveToken",
  auth_token: "auth",
  child_data: "child_data",
  default_child: "default_child",
  parent_data: "parent_data",
  session_data: "session_data",
  freadom_child_data: "freadom_child_data",
  redux_persist: "persist:freadom",
  device_id: "device_id",
  recentSearchData: "recent_search_data",
  // is_forced_logout:"is_forced_logout"
};

const set = {
  authToken: (data, setFlive) => {
    if (setFlive)
      return localStorage.setItem(
        LS_KEY.flive_token,
        JSON.stringify({
          auth_token: data,
        })
      );
    localStorage.setItem(
      LS_KEY.auth_token,
      JSON.stringify({
        auth_token: data,
      })
    );
  },
  childData: (data) => {
    localStorage.setItem(
      LS_KEY.child_data,
      JSON.stringify({
        child_data: data,
      })
    );
  },
  defaultChild: (data) => {
    localStorage.setItem(
      LS_KEY.default_child,
      JSON.stringify({
        default_child: data,
      })
    );
  },
  parentData: (data) => {
    localStorage.setItem(
      LS_KEY.parent_data,
      JSON.stringify({
        parent_data: data,
      })
    );
  },
  sessionData: (uuid) => {
    localStorage.setItem(
      LS_KEY.session_data,
      JSON.stringify({
        session_data: uuid,
      })
    );
  },
  freadomChild: (data) => {
    localStorage.setItem(
      LS_KEY.freadom_child_data,
      JSON.stringify({
        freadom_child_data: data,
      })
    );
  },
  deviceId: (data) => {
    localStorage.setItem(
      LS_KEY.device_id,
      JSON.stringify({
        device_id: data,
      })
    );
  },
  recentSearchData: (data) => {
    let childId = JSON.parse(localStorage.getItem(LS_KEY.default_child))?.default_child?.id;

    // console.log(childId)
    if (childId && data) {
        // console.log('inside')
      let recentSearchData =
        JSON.parse(localStorage.getItem(LS_KEY.recentSearchData)) || {};

      // Initialize the childId's data if it doesn't exist
      if (!recentSearchData[childId]) {
        recentSearchData[childId] = [];
      }

      // Add the new data to the child's recent search data
      recentSearchData[childId].push(data);

      // Update localStorage with the new recent search data
      localStorage.setItem(
        LS_KEY.recentSearchData,
        JSON.stringify(recentSearchData)
      );
    }

  },
  // forceLogout: (data) => {
  //     localStorage.setItem(
  //         LS_KEY.is_forced_logout,
  //         JSON.stringify(data)
  //     )
  // }
};

const fetch = {
  authToken: (getFlive) => {
    let keyToFetch = getFlive ? LS_KEY.flive_token : LS_KEY.auth_token;
    const data = localStorage.getItem(keyToFetch);
    if (data) {
      try {
        const decoded = JSON.parse(data);
        return decoded.auth_token;
      } catch (err) {
        console.log(err);
      }
    }
  },
  childData: () => {
    const data = localStorage.getItem(LS_KEY.child_data);
    if (data) {
      try {
        const decoded = JSON.parse(data);
        return decoded.child_data;
      } catch (err) {
        console.log(err);
      }
    }
  },
  defaultChild: () => {
    const data = localStorage.getItem(LS_KEY.default_child);
    if (data) {
      try {
        const decoded = JSON.parse(data);
        return decoded.default_child;
      } catch (err) {
        console.log(err);
      }
    }
  },
  parentData: () => {
    const data = localStorage.getItem(LS_KEY.parent_data);
    if (data) {
      try {
        const decoded = JSON.parse(data);
        return decoded.parent_data;
      } catch (err) {
        console.log(err);
      }
    }
  },
  freadomChild: () => {
    const data = localStorage.getItem(LS_KEY.freadom_child_data);
    if (data) {
      try {
        const decoded = JSON.parse(data);
        return decoded.freadom_child_data;
      } catch (err) {
        console.log(err);
      }
    }
  },
  sessionData: () => {
    const data = localStorage.getItem(LS_KEY.session_data);
    if (data) {
      try {
        const decoded = JSON.parse(data);
        return decoded;
      } catch (err) {
        console.log(err);
      }
    }
  },
  deviceId: () => {
    const data = localStorage.getItem(LS_KEY.device_id);
    if (data) {
      try {
        const decoded = JSON.parse(data);
        return decoded;
      } catch (err) {
        console.log(err);
      }
    }
  },
  recentSearchData: () => {
    const data = localStorage.getItem(LS_KEY.recentSearchData);
    let childId = JSON.parse(localStorage.getItem(LS_KEY.default_child))?.default_child?.id;
    if (data) {
      try {
        const decodedData = JSON.parse(data);
        let decoded = decodedData[childId]
        return decoded;
      } catch (err) {
        console.log(err);
      }
    }
  },
  // forceLogout: () => {
  //     const data = localStorage.getItem(LS_KEY.is_forced_logout);
  //     if (data) {
  //         try {
  //             const decoded = JSON.parse(data);
  //             return decoded
  //         } catch (err) {
  //             console.log(err);
  //         }
  //     }
  // }
};

const destroy = {
  authToken: () => {
    localStorage.removeItem(LS_KEY.auth_token);
    localStorage.removeItem(LS_KEY.flive_token);
  },
  childData: () => {
    localStorage.removeItem(LS_KEY.child_data);
  },
  defaultChild: () => {
    localStorage.removeItem(LS_KEY.default_child);
  },
  parentData: () => {
    localStorage.removeItem(LS_KEY.parent_data);
  },
  sessionData: () => {
    localStorage.removeItem(LS_KEY.session_data);
  },
  all: () => {
    localStorage.removeItem(LS_KEY.auth_token);
    localStorage.removeItem(LS_KEY.child_data);
    localStorage.removeItem(LS_KEY.default_child);
    localStorage.removeItem(LS_KEY.parent_data);
    localStorage.removeItem(LS_KEY.session_data);
    localStorage.removeItem(LS_KEY.redux_persist);
    localStorage.removeItem(LS_KEY.device_id);
    localStorage.removeItem(LS_KEY.recentSearchData);
  },
  reduxPersist: () => {
    localStorage.removeItem(LS_KEY.redux_persist);
  },
};

export const storage = {
  set,
  fetch,
  destroy,
};
