import { User } from "services/user";
import { mutation } from "./mutations";
import { storage as LocalStorage } from "services/config/storage";
import { mutation as StudentMutations } from "../student/mutations";
// import { Student } from "../../services/student";
import { Analytics } from "../../services/analytics";

const handleError = (err) => {
  return err.response || err;
};

// Actions
export const userLoginRequest = (payload) => async (dispatch) => {
  try {
    const { result } = await User.userLogin(payload);
    LocalStorage.set.parentData(result);
    dispatch({
      type: "RESET_STATE",
    });
    LocalStorage.destroy.reduxPersist();
    dispatch(mutation.setUserLoginDetail(result));
    await getAndSetChild(dispatch, result);
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const triggerOtpSend = (payload) => async (dispatch) => {
  try {
    const result = await User.otpSend(payload);
    return result;
  } catch (err) {
    handleError(err);
  }
};
//FLV-113
export const setNavbarFlag = (payload) => async (dispatch) => {
  try {
    dispatch(mutation.setNavbarFlag(payload));
  } catch (err) {
    handleError(err);
  }
};
//FLV-113

export const getSchool = (code) => async (dispatch) => {
  try {
    const result = await User.getSchool(code);
    return result;
  } catch (err) {
    handleError(err);
  }
};
export const getClass = (payload) => async (dispatch) => {
  try {
    const result = await User.getClass(payload);
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const getLanguages = () => async (dispatch) => {
  try {
    const result = await User.getLanguages();
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const getLanguagesBySchool = (school_id) => async (dispatch) => {
  try {
    console.log(school_id)
    const result = await User.getLanguagesBySchool(school_id);
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const triggerCallOtpSend = (payload) => async (dispatch) => {
  try {
    const result = await User.callOtpSend(payload);
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const verifyOtpRequest = (payload) => async (dispatch) => {
  try {
    var result = await User.verifyOtp(payload);
    dispatch(mutation.setUserAuthToken(result?.auth_token));
    LocalStorage.set.authToken(result?.auth_token);
    result = { ...result, is_teacher: payload.is_teacher };
    await setParentData(result, null, dispatch);
    return result;
  } catch (err) {
    handleError(err);
  }
};
export const handleAutoLogin = (freadomToken, childId) => async (dispatch) => {
  let headers = {
    Authorization: `JWT ${freadomToken}`,
  };
  try {
    const userData = await User.getUserDetail(headers);
    dispatch(mutation.setUserAuthToken(freadomToken));
    LocalStorage.set.authToken(freadomToken);
    await setParentData(userData, childId, dispatch);
    return userData;
  } catch (err) {
    handleError(err);
  }
};
export const setParentData = async (parentData, childId, dispatch) => {
  try {
    LocalStorage.set.parentData(parentData);
    dispatch(mutation.setUserLoginDetail(parentData));
    await getAndSetChild(dispatch, parentData, childId);
    return parentData;
  } catch (err) {
    handleError(err);
  }
};

export const getEmailOtpAction = (data) => async (dispatch) => {
  try {
    const userData = await User.getEmailOtp(data);
    return userData;
  } catch (err) {
    handleError(err);
  }
};

export const verifyEmailOtpAction = (otpData) => async (dispatch) => {
  try {
    let parentData = LocalStorage.fetch.parentData();
    const userData = await User.verifyEmailOtp(otpData);
    if (userData) {
      dispatch(mutation.setUserLoginDetail(userData));

      Object.assign(parentData, userData);
      LocalStorage.set.parentData(parentData);
    }
    return userData;
  } catch (err) {
    handleError(err);
  }
};

export const verifyParentOtpRequest = (payload) => async (dispatch) => {
  try {
    const result = await User.verifyParentOtp(payload);
    LocalStorage.set.parentData(result.result);
    LocalStorage.set.authToken(result.result.auth_token);
    dispatch(mutation.setUserLoginDetail(result.result));
    return result.result;
  } catch (err) {
    handleError(err);
  }
};
export const createChild = (payload, user) => async (dispatch) => {
  try {
    const result = await User.createChildCall(payload);
    console.log("result", result);
    if (result.status != 400 || result.status != 500 || result.status != 404) {
      await getAndSetChild(dispatch, user, result.result?.id);
    }
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const addSchool = (payload, user) => async (dispatch) => {
  try {
    const result = await User.addSchoolToChild(payload);
    console.log("addSchool-result", result);
    if (result) {
      await getAndSetChild(dispatch, user, result.result?.id);
    }
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const getGrades = () => async (dispatch) => {
  try {
    const result = await User.getGradeList();
    dispatch(mutation.setGrades(result));
    return result;
  } catch (err) {
    handleError(err);
  }
};
export const switchChild = (payload) => async (dispatch) => {
  try {
    const result = await User.selectChild(payload);
    const newDefaultChild = LocalStorage.fetch
      .childData()
      .list.filter((d) => d.id === payload.child_id)[0];
    // let fliveStudentResponse = await Student.loginWithFreadom(
    //   newDefaultChild.id
    // );
    let fliveStudentResponse = { status: 404 };
    dispatch(mutation.setUserAuthToken, result.result.auth_token);
    //TODO: Remove hardcoded mutations
    // dispatch({
    //     type: "RESET_STATE",
    // })
    // LocalStorage.destroy.reduxPersist();
    LocalStorage.set.authToken(result.result.auth_token);
    LocalStorage.set.authToken(fliveStudentResponse?.data?.token, true);
    LocalStorage.set.freadomChild(fliveStudentResponse?.data?.child);
    dispatch(
      StudentMutations.setProfileInfo(fliveStudentResponse?.data?.child)
    );
    LocalStorage.set.defaultChild(newDefaultChild);
    //FREAD-1301
    Analytics.onLogin();
    //FREAD-1301
    if (fliveStudentResponse?.status === 404) {
      return fliveStudentResponse;
    }
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const switchTeacherClass = (payload) => async (dispatch) => {
  try {
    const defaultChildData = LocalStorage.fetch.defaultChild();

    const updatedChildData = {
      ...defaultChildData,
      TeacherSchoolClass: payload,
    };
    LocalStorage.set.defaultChild(updatedChildData);

    // return LocalStorage.fetch.defaultChild()
  } catch (err) {
    handleError(err);
  }
};

export const euroLogin =
  ({ token, email }) =>
  async (dispatch) => {
    try {
      const euroHeader = {
        Authorization: token,
        "api-version": "1.1.0",
        "Content-Type": "application/json",
      };
      const newToken = await User.getTokenWithEuroData({ email }, euroHeader);
      LocalStorage.set.authToken(newToken.result.auth_token);
      const userData = await User.getUserDetail();
      LocalStorage.set.parentData(userData);
      await getAndSetChild(dispatch, {
        auth_token: newToken.result.auth_token,
      });
      return true;
    } catch (err) {
      handleError(err);
    }
  };
//FREAD-1233
export const parent_update = () => async (dispatch) => {
  const userData = await User.getUserDetail();
  LocalStorage.set.parentData(userData);
};
export const batchIdUpdate = (id) => async (dispatch) => {
  dispatch(mutation.setBatchID(id));
};
//FREAD-1233
export const getAndSetChild = async (
  dispatch,
  result,
  childId,
  checkIfOnboardedFlive = true
) => {
  const childList = await User.getChildrenList();
  console.log("childList", childList);
  LocalStorage.set.childData({ list: childList });
  if (childList) {
    let childData = childList[0];
    console.log("childData", childData);
    console.log("childId", childId);
    if (childId) {
      childData = childList?.filter((item) => item.id === childId)?.[0];
    }
    LocalStorage.set.defaultChild(childData);
    dispatch(mutation.setChildList(childList));
    dispatch(mutation.setDefaultChild(childData));
    const selectedChild = await User.selectChild({ child_id: childData.id });
    LocalStorage.set.authToken(selectedChild.result.auth_token);
    dispatch(mutation.setUserAuthToken(selectedChild.result.auth_token));
    if (checkIfOnboardedFlive) {
      let fliveStudentResponse = { status: 404 };
      // let fliveStudentResponse = await Student.loginWithFreadom(childData.id);
      LocalStorage.set.authToken(fliveStudentResponse?.data?.token, true);
      LocalStorage.set.freadomChild(fliveStudentResponse?.data?.child);
      dispatch(
        StudentMutations.setProfileInfo(fliveStudentResponse?.data?.child)
      );
      return fliveStudentResponse;
    }
  }
};

export const getChildDetails = (payload) => async (dispatch) => {
  try {
    const result = await User.getChildDetails(payload);
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const loginWithAuthToken = (payload) => async (dispatch) => {
  try {
    const result = await User.loginWithAuthToken(payload);
    LocalStorage.set.parentData(result.result);
    LocalStorage.set.authToken(result.result.auth_token);
    dispatch(mutation.setUserLoginDetail(result.result));

    // get child details
    getAndSetChild(dispatch);
    return result.result;
  } catch (err) {
    handleError(err);
  }
};

export const createTeacherRequest = (payload) => async (dispatch) => {
  try {
    const result = await User.createTeacher(payload);
    let token = result.result.data.auth_token;
    LocalStorage.set.authToken(token);
    return result;
  } catch (err) {
    handleError(err);
  }
};
