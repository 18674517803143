import React from 'react';

export const TrashCancel = () => <svg width="16" height="17" viewBox="0 0 16 17" fill="none"
                                      xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M11.5295 3.19596H13.5295C13.8976 3.19596 14.1961 3.49444 14.1961 3.86263C14.1961 4.23082 13.8976 4.5293 13.5295 4.5293H1.52946C1.16127 4.5293 0.862793 4.23082 0.862793 3.86263C0.862793 3.49444 1.16127 3.19596 1.52946 3.19596H3.52946V2.5293C3.52946 1.42473 4.42489 0.529297 5.52946 0.529297H9.52946C10.634 0.529297 11.5295 1.42473 11.5295 2.5293V3.19596ZM4.86279 3.19598H10.1961V2.52932C10.1961 2.16113 9.89765 1.86265 9.52946 1.86265H5.52946C5.16127 1.86265 4.86279 2.16113 4.86279 2.52932V3.19598ZM2.11674 15.276C2.15911 15.9809 2.74384 16.5306 3.45007 16.5293H11.6101C12.3163 16.5306 12.901 15.9809 12.9434 15.276L13.5301 5.86265H1.53007L2.11674 15.276ZM5.17099 8.83595C4.90957 9.09737 4.90957 9.52121 5.17099 9.78262L6.59099 11.196L5.17099 12.6093C4.90957 12.8707 4.90957 13.2945 5.17099 13.556C5.4324 13.8174 5.85624 13.8174 6.11765 13.556L7.53099 12.136L8.94432 13.556C9.11343 13.7251 9.3599 13.7911 9.5909 13.7292C9.82191 13.6673 10.0023 13.4869 10.0642 13.2559C10.1261 13.0249 10.0601 12.7784 9.89099 12.6093L8.47099 11.196L9.89099 9.78262C10.0601 9.61351 10.1261 9.36704 10.0642 9.13603C10.0023 8.90503 9.82191 8.7246 9.5909 8.6627C9.3599 8.6008 9.11343 8.66685 8.94432 8.83595L7.53099 10.256L6.11765 8.83595C5.85624 8.57454 5.4324 8.57454 5.17099 8.83595Z"
          fill="#EB5757"/>
</svg>
export const QuestionIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="56"
    fill="none"
    viewBox="0 0 56 56"
  >
    <g filter="url(#filter0_d)">
      <path
        fill="#CB5499"
        d="M43.941 8C34.56-1.349 19.38-1.331 10.02 8.04c-9.36 9.37-9.36 24.55 0 33.92 9.36 9.371 24.54 9.389 33.921.04C48.46 37.497 51 31.38 51 25c0-6.38-2.54-12.497-7.059-17z"
      />
    </g>
    <path
      fill="#fff"
      d="M27.92 36.981c0 1.105-.895 2-2 2-1.104 0-2-.895-2-2s.896-2 2-2c1.105 0 2 .895 2 2zM35.92 20.98c0 1.889-.888 3.667-2.4 4.8l-6.4 4.8c-.345.26-.766.4-1.2.4-.86 0-1.624-.55-1.897-1.368-.272-.816.01-1.715.698-2.232l6.4-4.8c.504-.378.8-.97.8-1.6v-2c0-1.105-.895-2-2-2h-6c-1.105 0-2 .895-2 2 0 1.104-.895 2-2 2s-2-.896-2-2c0-3.314 2.686-6 6-6h6c3.314 0 6 2.686 6 6v2z"
    />
    <defs>
      <filter
        id="filter0_d"
        width="56"
        height="56"
        x="0"
        y="0"
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx="1" dy="3" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);

export const Calender15Icon = ({width, height, color}) => <svg xmlns="http://www.w3.org/2000/svg" width={width || "24"}
                                                               height={height || "24"} fill={color ? color : "#eeeeee"}
                                                               viewBox="0 0 24 24">
    <path fill="#9134D8" fill-rule="evenodd"
          d="M22 2h-3V1c0-.552-.448-1-1-1s-1 .448-1 1v1H7V1c0-.552-.448-1-1-1S5 .448 5 1v1H2c-.53 0-1.039.21-1.414.586C.21 2.96 0 3.47 0 4v18c0 .53.21 1.039.586 1.414C.96 23.79 1.47 24 2 24h20c.53 0 1.039-.21 1.414-.586C23.79 23.04 24 22.53 24 22V4.002c0-.531-.21-1.04-.586-1.415C23.04 2.21 22.53 2 22 2zM5 6c0-.552.448-1 1-1s1 .448 1 1-.448 1-1 1-1-.448-1-1zm4 14c.552 0 1-.448 1-1v-8c0-.347-.18-.668-.474-.85-.295-.183-.663-.2-.973-.045l-2 1c-.493.248-.692.848-.445 1.341.246.493.845.694 1.34.449L8 12.618V19c0 .552.448 1 1 1zm4 0c-.552 0-1-.448-1-1s.448-1 1-1h2c.552 0 1-.448 1-1s-.448-1-1-1h-2c-.265 0-.52-.105-.707-.293C12.105 15.52 12 15.265 12 15v-4c0-.265.105-.52.293-.707.187-.188.442-.293.707-.293h3.5c.552 0 1 .448 1 1s-.448 1-1 1H14v2h1c1.657 0 3 1.343 3 3s-1.343 3-3 3h-2zm4-14c0 .552.448 1 1 1s1-.448 1-1-.448-1-1-1-1 .448-1 1z"
          clip-rule="evenodd"/>
</svg>

export const CheckIcon = ({width, height, color}) => <svg id="Capa_1" viewBox="0 0 512 512"
                                                          fill={color ? color : "#eeeeee"} width={width || "20"}
                                                          height={height || "20"} xmlns="http://www.w3.org/2000/svg">
    <g>
        <path
            d="m256 0c-141.49 0-256 114.5-256 256 0 141.49 114.5 256 256 256 141.49 0 256-114.5 256-256 0-141.49-114.5-256-256-256zm-60.914 363.99s-88.422-88.458-94.778-94.802c-14.139-14.139-14.139-37.147 0-51.274 14.175-14.175 37.099-14.175 51.274 0l57.244 57.244c7.118 7.118 18.67 7.069 25.728-.085l125.69-127.502c14.127-14.332 37.208-14.429 51.455-.181 14.03 14.03 14.115 36.942.181 51.081-136.493 138.486-162.414 165.507-162.414 165.507-14.985 14.984-39.383 14.997-54.38.012z"/>
    </g>
</svg>
export const TimeIcon = ({color, width, height}) => <svg xmlns="http://www.w3.org/2000/svg" width={width || "10"}
                                                         height={height || "10"} viewBox="0 0 10 10">
    <path fill={color || "#3E2272"} fill-rule="nonzero"
          d="M5 0a5 5 0 1 0 0 10A5 5 0 0 0 5 0zm.417 5.833h-2.5a.417.417 0 1 1 0-.833h1.666V2.5a.417.417 0 0 1 .834 0v3.333z"/>
</svg>

export const GradeAIcon = ({width, height, color}) => <svg xmlns="http://www.w3.org/2000/svg" width={width || "15"}
                                                           height={height || "18"} fill={color ? color : "#eeeeee"}
                                                           viewBox="0 0 15 18">
    <path fill="#534F5A" fill-rule="evenodd"
          d="M8.7 0H1.45C.65 0 0 .65 0 1.45v14.5c0 .8.65 1.45 1.45 1.45h11.6c.8 0 1.45-.65 1.45-1.45V5.8H8.7V0zm1.45 4.35V0l4.35 4.35h-4.35zm-2.087 7.596l-1.733.217.92-1.842.813 1.625zm2.722-.34l-1.196.15-1.69-3.38c-.136-.229-.383-.369-.649-.369s-.512.14-.648.369l-2.9 5.8c-.18.358-.034.793.324.973.358.179.794.034.973-.325l.551-1.103 3.2-.4.752 1.503c.179.358.614.504.973.325.358-.18.503-.615.324-.973l-.523-1.046.69-.086c.396-.05.678-.412.629-.809-.05-.397-.412-.679-.81-.63z"
          clip-rule="evenodd"/>
</svg>

export const BackpackIcon = ({color, width, height}) => <svg xmlns="http://www.w3.org/2000/svg" width={width || "20"}
                                                             height={height || "24"} fill="none" viewBox="0 0 20 24">
    <path fill={color || "#534F5A"} fillRule="evenodd"
          d="M14 3v1c3.314 0 6 2.686 6 6v12c0 1.105-.895 2-2 2v-8c-.003-2.76-2.24-4.997-5-5H7c-2.76.003-4.997 2.24-5 5v8c-1.105 0-2-.895-2-2V10c0-3.314 2.686-6 6-6V3C6.002 1.344 7.344.002 9 0h2c1.656.002 2.998 1.344 3 3zM8 4h4V3c0-.552-.448-1-1-1H9c-.552 0-1 .448-1 1v1zm5 9H7c-1.657 0-3 1.343-3 3v8h12v-8c0-1.657-1.343-3-3-3zm-6 5h6c.552 0 1-.448 1-1s-.448-1-1-1H7c-.552 0-1 .448-1 1s.448 1 1 1z"
          clipRule="evenodd"/>
</svg>
export const DefaultUserIcon = ({width, height}) => <svg width={width || "35"} height={height || "35"}
                                                         viewBox="0 0 35 35" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M18 1C8.61116 1 1 8.61116 1 18C1 27.3888 8.61116 35 18 35C27.3888 35 35 27.3888 35 18C35 8.61116 27.3888 1 18 1ZM18 8.08333C21.5208 8.08333 24.375 10.9375 24.375 14.4583C24.375 17.9791 21.5208 20.8333 18 20.8333C14.4792 20.8333 11.625 17.9791 11.625 14.4583C11.6292 10.9393 14.4809 8.08754 18 8.08333ZM8.5046 28.4825C11.0997 30.8515 14.4862 32.1655 18 32.1667C21.5138 32.1655 24.9003 30.8515 27.4954 28.4825C26.2973 25.7087 22.5104 23.6667 18 23.6667C13.4899 23.6667 9.70269 25.7087 8.5046 28.4825Z"
          fill="#ECECEC"/>
</svg>

export const CommentIcon = () => <svg width="12" height="20" viewBox="0 0 12 11" fill="none"
                                      xmlns="http://www.w3.org/2000/svg">
    <path
        d="M6.66679 8.66646H7.66677C10.0599 8.66646 12 6.72641 12 4.33323C12 1.94005 10.0599 4.81111e-07 7.66677 4.81111e-07H4.33352C2.13542 -0.00102725 0.285108 1.64462 0.0296447 3.82782C-0.225818 6.01103 1.19459 8.03939 3.33354 8.54586V10.6664L6.66679 8.66646Z"
        fill="#524E59"/>
</svg>

export const CrossIcon = () => <svg width="12" height="13" viewBox="0 0 12 13" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M8.0876 6.02097L11.5633 2.54049C12.1465 1.95647 12.1434 1.02362 11.5669 0.446353C10.9864 -0.134942 10.0542 -0.129283 9.47569 0.450005L6 3.93049L2.52431 0.450005C1.9411 -0.134014 1.00954 -0.130918 0.43306 0.446353C-0.147434 1.02765 -0.141782 1.9612 0.436707 2.54049L3.9124 6.02097L0.436707 9.50145C-0.146507 10.0855 -0.143415 11.0183 0.43306 11.5956C1.01355 12.1769 1.94582 12.1712 2.52431 11.5919L6 8.11145L9.47569 11.5919C10.0589 12.176 10.9905 12.1729 11.5669 11.5956C12.1474 11.0143 12.1418 10.0807 11.5633 9.50145L8.0876 6.02097Z"
          fill="#524E59"/>
</svg>
export const EditIcon = ({ height = 11, width = 11, color = "#CB5499" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4632 3.12019C10.9264 2.65845 11.1073 1.98546 10.9378 1.35473C10.7682 0.723987 10.274 0.231325 9.64137 0.0623198C9.00869 -0.106685 8.33363 0.0736426 7.87047 0.535376L6.97559 1.42752L9.56835 4.01234L10.4632 3.12019Z"
      fill={color}
    />
    <path
      d="M8.7039 4.87437L6.11113 2.28955L1.01871 7.36636L0 10.9668L3.61149 9.95118L8.7039 4.87437Z"
      fill="#CB5499"
    />
  </svg>
);
export const EditPaperIcon = ({height = 27, width = 30, color = 'white', ...props}) => <svg width={width}
                                                                                            height={height}
                                                                                            viewBox="0 0 30 27"
                                                                                            fill="none"
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            {...props}
>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M28.6285 0.987513C27.2106 -0.329822 24.9129 -0.329822 23.495 0.987513L21.7949 2.56823L26.9284 7.34152L28.6398 5.75033C29.3192 5.11813 29.6998 4.26043 29.6977 3.36674C29.6956 2.47305 29.3109 1.61692 28.6285 0.987513ZM15.9066 20.7725L15.0432 20.8506L9.36388 21.3645L6.4399 21.629L6.72082 18.9099L7.26698 13.623L7.35014 12.8186L7.96462 12.2472L18.3735 2.56874L18.3736 2.56867L18.3735 2.56854L18.7161 2.25H3.07998C1.74356 2.25004 0.660196 3.25738 0.660156 4.50001V24.7501C0.660196 25.9927 1.74356 27.0001 3.07998 27.0001H22.4385C23.775 27.0001 24.8583 25.9927 24.8584 24.7501V12.4488L16.52 20.2022L15.9066 20.7725ZM9.12867 19.1258L9.67485 13.8387L20.0842 4.15967L25.2177 8.93295L14.8083 18.612L9.12867 19.1258Z"
          fill={color}/>
</svg>
export const SyncIcon = ({height = 28, width = 28, color = '#F24E29', ...props}) => <svg width={width} height={height}
                                                                                         viewBox="0 0 28 28"
                                                                                         fill="none"
                                                                                         xmlns="http://www.w3.org/2000/svg"
                                                                                         {...props}
>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M22.8535 4.53475L25.1444 2.24385C25.445 1.83456 25.9883 1.68681 26.4548 1.88748C26.9212 2.08814 27.1876 2.58415 27.0971 3.08385V11.7566H18.3917C17.8988 11.8261 17.4213 11.5532 17.2311 11.0931C17.0409 10.6331 17.1863 10.1027 17.5844 9.80384L19.7662 7.62203C17.7355 5.64512 14.8636 4.79138 12.0827 5.33797C9.3019 5.88455 6.96672 7.76178 5.83533 10.3602C5.50556 11.1756 4.71845 11.7132 3.83897 11.7238H3.7626C3.04566 11.7081 2.38222 11.3411 1.98796 10.7421C1.5937 10.1431 1.51897 9.38856 1.78806 8.72385C3.47235 4.77101 7.00053 1.90535 11.2147 1.06732C15.4289 0.229277 19.785 1.52706 22.8535 4.53475ZM24.1772 16.0868H24.2535C24.985 16.0952 25.6636 16.4696 26.0608 17.084C26.4579 17.6984 26.5207 18.4709 26.2281 19.1413C24.5438 23.0942 21.0156 25.9598 16.8014 26.7979C12.5872 27.6359 8.23108 26.3381 5.16261 23.3304L2.8717 25.6213C2.57289 26.0195 2.04244 26.1649 1.58239 25.9747C1.12235 25.7845 0.8494 25.307 0.91897 24.8141V16.0868H9.64624C10.1172 16.0522 10.557 16.3246 10.7358 16.7617C10.9146 17.1988 10.7919 17.7013 10.4317 18.0068L8.24988 20.1886C10.2806 22.1655 13.1525 23.0193 15.9334 22.4727C18.7142 21.9261 21.0494 20.0488 22.1808 17.4504C22.5106 16.635 23.2977 16.0974 24.1772 16.0868Z"
          fill={color}/>
</svg>
export const ChevronRight = ({height = 10, width = 5, color = 'white', ...props}) => <svg width={width} height={height}
                                                                                          viewBox="0 0 5 10"
                                                                                          fill="none"
                                                                                          xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1.5L4 5L1 8.5" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>;
export const QuestionMarkIcon = ({height = 63, width = 63, color = 'white', ...props}) => <svg width={width}
                                                                                               height={height}
                                                                                               viewBox="0 0 63 63"
                                                                                               fill="none"
                                                                                               xmlns="http://www.w3.org/2000/svg">
    <path
        d="M53.7352 9.1875C41.4224 -3.08259 21.4976 -3.05953 9.21325 9.23903C-3.07108 21.5376 -3.07108 41.4624 9.21325 53.761C21.4976 66.0595 41.4224 66.0826 53.7352 53.8125C59.6662 47.902 63 39.8733 63 31.5C63 23.1267 59.6662 15.098 53.7352 9.1875Z"
        fill="#9134D8"/>
    <path
        d="M32.709 47.2251C32.709 48.6748 31.5337 49.8501 30.084 49.8501C28.6342 49.8501 27.459 48.6748 27.459 47.2251C27.459 45.7754 28.6342 44.6001 30.084 44.6001C31.5337 44.6001 32.709 45.7754 32.709 47.2251Z"
        fill="white"/>
    <path
        d="M43.209 26.2236C43.209 28.7023 42.042 31.0364 40.059 32.5236L31.659 38.8236C31.2046 39.1644 30.652 39.3486 30.084 39.3486C28.9541 39.3486 27.951 38.6256 27.5937 37.5537C27.2364 36.4818 27.6051 35.3016 28.509 34.6236L36.909 28.3236C37.57 27.8279 37.959 27.0499 37.959 26.2236V23.5986C37.959 22.1489 36.7837 20.9736 35.334 20.9736H27.459C26.0092 20.9736 24.834 22.1489 24.834 23.5986C24.834 25.0484 23.6587 26.2236 22.209 26.2236C20.7592 26.2236 19.584 25.0484 19.584 23.5986C19.584 19.2494 23.1097 15.7236 27.459 15.7236H35.334C39.6832 15.7236 43.209 19.2494 43.209 23.5986V26.2236Z"
        fill="white"/>
</svg>;
export const PhoneIcon = ({height = 18, width = 18, color = 'black', ...props}) => <svg width={width} height={height}
                                                                                        viewBox="0 0 18 18" fill="none"
                                                                                        xmlns="http://www.w3.org/2000/svg">
    <path
        d="M15.1322 10.7022C14.8228 10.3928 14.4032 10.2189 13.9656 10.2189C13.528 10.2189 13.1084 10.3928 12.799 10.7022L10.8238 12.6772C8.33987 11.6357 6.36441 9.66027 5.32297 7.17634L7.29794 5.20137C7.94226 4.5571 7.94234 3.5125 7.29812 2.86813L4.91637 0.486196C4.60698 0.176768 4.18733 0.00292969 3.74975 0.00292969C3.31218 0.00292969 2.89253 0.176768 2.58313 0.486196L1.45057 1.61859C0.278991 2.79854 -0.228631 4.48412 0.0965002 6.11482C1.27298 12.0709 5.92928 16.7272 11.8853 17.9036C13.516 18.2288 15.2016 17.7213 16.3816 16.5497L17.514 15.4172C17.8234 15.1078 17.9972 14.6881 17.9972 14.2506C17.9972 13.813 17.8234 13.3933 17.514 13.0839L15.1322 10.7022Z"
        fill={color}/>
</svg>
export const MailIcon = ({height = 16, width = 18, color = 'black', ...props}) => <svg width={width} height={height}
                                                                                       viewBox="0 0 18 16" fill="none"
                                                                                       xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M1.5 0.5H16.5C17.3284 0.5 18 1.17157 18 2V3.77022L9.94295 7.48868C9.34474 7.76528 8.65527 7.76542 8.05696 7.48905L0 3.77026V2C0 1.17157 0.671573 0.5 1.5 0.5ZM7.42854 8.85097C7.92117 9.07847 8.45733 9.1963 8.99996 9.1963C9.54262 9.19635 10.0788 9.07839 10.5714 8.85061L18 5.42188V14C18 14.8284 17.3284 15.5 16.5 15.5H1.5C0.671573 15.5 0 14.8284 0 14V5.42195L7.42854 8.85097Z"
          fill={color}/>
</svg>




