import React, { Component } from "react";
import "./Header.scss";
import HelloLogo from 'assets/image/Hello_Logo.png'
import { Button } from "antd";

class Header extends Component {
  render() {
    return (
      <div className="header">
         <img src={HelloLogo} alt="logo"/>
         <Button
            type="primary"
            size="default"
            onClick={() =>window.open("https://go.getfreadom.com/more/freadom_app/","_blank")}
        >
         GET THE APP
        </Button>
      </div>
    );
  }
}

export default Header;
